import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import { useQueryParams } from '../../hooks/useQueryParams'
import { camelCase, toInteger } from 'lodash'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import {
  PaymentTransactionsQuery,
  PaymentTransactionsAvailableFilters,
} from '../../queries/paymentTransactions.gql'
import { getPaginationData, handleError, parseFilterQuery } from '../../utils/utils'
import FormattedDate from '../../ui-kit/components/text/FormattedDate'
import { Money, Text, ConfirmationDialog, Flex, Icon } from '../../ui-kit'
import PaymentContent from './paymentsTab/PaymentContent'
import StatusBadge from '../../ui-kit/components/badges/StatusBadge'
import { displayStatusesMapping } from './paymentsTab/util'
import { MONEY } from '../../utils/dataTypes'
import { useOpenEntitySidebar } from '../../hooks/useOpenEntitySidebar'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import { DEPOSIT, PAYMENT, paymentTypeMapping } from '../contracts/utils'
import DepositPaymentContent from './paymentsTab/DepositPaymentContent'
import { useNotifications } from '../../hooks/useNotifications'
import { VoidPaymentTransactionRequest } from '../../queries/mutations/voidPaymentTransaction.gql'
import colors from '../../ui-kit/colors'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { getPaymentsBreadcrumbs } from './paymentsUtils'
import { getLoadingFunc } from '../invoices/invoicesUtils'
import { ContractsAutocompleteQuery } from '../../queriesUpdated/queries/contract.gql'
import VoidErrorMessageDialog from './VoidErrorMessageDialog'

const PaymentsTab = ({ contractData, isAllContracts, scope }) => {
  const { queryParams } = useQueryParams()
  const sort = queryParams.sort || 'entry_date.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [isVoidModalOpened, setIsVoidModalOpened] = useState(false)
  const [isVoidErrorModalOpened, setIsVoidErrorModalOpened] = useState(false)
  const [voidErrorCode, setVoidErrorCode] = useState()
  const [voidedTransactionId, setVoidedTransactionId] = useState(false)
  const [selectedPaymentTransaction, setSelectedPaymentTransaction] = useState(null)
  useEffect(() => {
    setBreadcrumbs(getPaymentsBreadcrumbs(contractData, t, scope))

    return function cleanup() {
      setAvailableFilters(null)
    }
  }, [scope, contractData])
  const [availableFilters, setAvailableFilters] = useState(null)
  const filtersQuery = queryParams.filters || null
  const userFilters = parseFilterQuery(filtersQuery)

  const pageSize = useMemo(() => 100, [])
  const queryVariables = useMemo(() => {
    const variables = {
      filters: [...userFilters, { key: 'charge_type', values: ['sale'] }],
      sort,
      page,
      search,
      perPage: pageSize,
    }

    if (contractData?.id) {
      variables.filters.push({
        key: 'contract_id',
        values: [contractData.id],
      })
    }

    return variables
  }, [userFilters, contractData?.id, sort, page, search, pageSize])

  const { data, loading } = useCustomQuery({
    query: PaymentTransactionsQuery,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'PaymentTransactionsQuery', target: 'PaymentsTab' },
  })
  const onCompleted = useCallback((response) => {
    response?.paymentTransactions?.availableFilters &&
      setAvailableFilters(response.paymentTransactions.availableFilters)
  }, [])
  useCustomQuery({
    query: PaymentTransactionsAvailableFilters,
    onCompleted,
    rollbarOptions: { operationName: 'PaymentTransactionsAvailableFilters', target: 'PaymentsTab' },
  })
  const paymentTransactions = data?.paymentTransactions
  const rows = paymentTransactions?.data || []
  const paginationData = getPaginationData(paymentTransactions)
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )

  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'InvoicesOutstanding' },
  })
  const loadContractOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')

  const columns = useMemo(
    () => [
      {
        field: 'entryDate',
        filterId: 'entryDate',
        headerName: t('date'),
        renderCell: (values) => (
          <FormattedDate date={values?.row?.entryDate} format={'MM/dd/yyyy'} />
        ),
        width: 90,
      },
      {
        field: 'id',
        filterId: additionalFilters.includes('id') && 'id',
        headerName: t('id'),
        flex: 15,
      },
      {
        field: 'customerName',
        headerName: t('customer'),
        renderCell: (values) => values?.row?.payable?.contract?.buyer?.name,
        filterId: isAllContracts && 'contractId',
        loadOptions: loadContractOptions,
        flex: 30,
        hide: !isAllContracts,
      },
      {
        field: 'payableType',
        filterId: 'payableType',
        headerName: t('type'),
        renderCell: (values) => t(paymentTypeMapping[values?.row?.payableType]),
        width: 70,
      },
      {
        field: 'totalAmountCents',
        filterId: 'totalAmount',
        filterDataType: MONEY,
        headerName: t('totalPmt'),
        width: 106,
        renderCell: (values) => (
          <Money
            value={
              values.row?.payableType === PAYMENT
                ? values.row?.payable?.totalAmountCents
                : values.row?.chargeAmountCents
            }
          />
        ),
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'adjustmentsAppliedCents',
        headerName: t('adj'),
        width: 106,
        renderCell: (values) => (
          <Money value={values?.row?.payable?.adjustmentsAppliedCents || 0} />
        ),
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        field: 'netPayment',
        headerName: t('netPmt'),
        width: 106,
        renderCell: (values) => <Money value={values?.row?.chargeAmountCents} />,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        field: 'feeAmountCents',
        headerName: t('ccFee'),
        width: 106,
        renderCell: (values) => <Money value={values.row?.feeAmountCents} />,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        field: 'chargeAmountCents',
        headerName: t('charged'),
        width: 106,
        renderCell: (values) => <Money value={values?.row?.totalAmountCents} />,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'paymentMethod',
        filterId: 'paymentMethodType',
        headerName: t('pmtMethod'),
        flex: 20,
        renderCell: (values) => values?.row?.paymentMethod?.title,
      },
      {
        field: 'financialEntity',
        filterId: 'depositedTo',
        headerName: t('depositedTo'),
        width: 122,
        renderCell: (values) => (
          <span className="text-ellipsis overflow-hidden">
            {values?.row?.payable?.financialEntity?.name || '-'}
          </span>
        ),
      },
      {
        field: 'attachmentIcon',
        headerName: '',
        sortable: false,
        renderCell: (values) =>
          values.row.payable?.file && <Icon color="text-black-500" name="paperClip2" />,
        width: 3,
      },
      {
        field: 'status',
        filterTitle: t('status'),
        headerName: t('status'),
        filterId: 'status',
        width: 106,
        filterOptions: [
          {
            key: 'pending',
            title: 'Pending',
          },
          {
            key: 'successful',
            title: 'Successful',
          },
          {
            key: 'failed',
            title: 'Failed',
          },
          {
            key: 'voided',
            title: 'Voided',
          },
          {
            key: 'chargeback',
            title: 'Chargeback',
          },
        ],
        renderCell: (values) => (
          <StatusBadge
            color={displayStatusesMapping[values?.row?.displayStatus]?.color}
            value={t(displayStatusesMapping[values?.row?.displayStatus]?.label)}
          />
        ),
      },
      {
        field: 'homeLocation',
        filterId: isAllContracts && 'homeLocation',
        filterTitle: t('homeLocation'),
        hide: true,
      },
    ],
    [queryParams?.additionalFilters, loadContractOptions, isAllContracts],
  )

  const handleRowClick = (data) => {
    setSelectedPaymentTransaction(data.row)
    setIsSidebarOpened(true)
  }
  const { newNotification } = useNotifications()
  const handleOpenVoidModal = (id) => {
    setIsSidebarOpened(false)
    setVoidedTransactionId(id)
    setIsVoidModalOpened(true)
  }

  const customErrorHandling = useCallback((errors) => {
    if (errors.length) {
      const error = errors[0]
      const errorCode = error?.extensions?.errorCode
      if (errorCode) {
        setIsVoidErrorModalOpened(true)
        setVoidErrorCode(errorCode)
      } else {
        handleError(errors, newNotification)
      }
    }
  }, [])

  const [voidPaymentTransaction, { loading: voidInProgress }] = useCustomMutation({
    mutation: VoidPaymentTransactionRequest,
    customErrorHandling,
    rollbarOptions: { operationName: 'VoidPaymentTransactionRequest', target: 'PaymentsTab' },
    mutationOptions: {
      refetchQueries: [PaymentTransactionsQuery],
    },
  })

  const handleVoid = () => {
    voidPaymentTransaction({
      variables: { id: voidedTransactionId },
    }).then(({ data }) => {
      const responseData = data?.voidPaymentTransaction || {}

      if (responseData?.entity) {
        newNotification({ success: t('transactionHasBeenVoided') })
      }

      setIsVoidModalOpened(false)
    })
  }

  useOpenEntitySidebar(rows, setSelectedPaymentTransaction, setIsSidebarOpened)

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])
  const handleCancelVoidPayment = useCallback(() => {
    setIsSidebarOpened(true)
    setIsVoidModalOpened(false)
  }, [setIsSidebarOpened, setIsVoidModalOpened])

  const selectedContractId = useMemo(
    () => selectedPaymentTransaction?.payable?.contract?.id || contractData?.id,
    [selectedPaymentTransaction, contractData],
  )

  return (
    <>
      <div className="pt-4">
        <Sidebar isSidebarOpened={isSidebarOpened} setIsSidebarOpened={setIsSidebarOpened}>
          {selectedPaymentTransaction?.payableType === PAYMENT && (
            <PaymentContent
              attachmentRefetchQueries={[PaymentTransactionsQuery]}
              contractId={selectedContractId}
              id={selectedPaymentTransaction?.id}
              paymentMethod={selectedPaymentTransaction?.paymentMethod?.title}
              paymentMethodNickname={selectedPaymentTransaction?.paymentMethod?.nickname}
              voidTransaction={() => handleOpenVoidModal(selectedPaymentTransaction?.id)}
            />
          )}
          {selectedPaymentTransaction?.payableType === DEPOSIT && (
            <DepositPaymentContent
              id={selectedPaymentTransaction?.id}
              transaction={selectedPaymentTransaction || {}}
              voidTransaction={() => handleOpenVoidModal(selectedPaymentTransaction?.id)}
            />
          )}
        </Sidebar>

        <DataGridComponent
          availableFilters={availableFilters}
          columns={columns}
          loading={loading}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rowClassName="cursor-pointer"
          rowClick={handleRowClick}
          rows={rows}
          searchLabel={t('payments')}
          selectionModel={selectedPaymentTransaction ? [selectedPaymentTransaction.id] : []}
          sortModel={defaultSortModel}
        />
      </div>
      <ConfirmationDialog
        confirmationMessage={
          <Flex column>
            {t('voidWarningMsg')}
            <Text
              className="pt-4"
              color={colors.GRAY_700}
              fontWeight={fontWeight.MEDIUM}
              size={sizes.SM}>
              {t('voidsCannotBeUndone')}
            </Text>
          </Flex>
        }
        isOpened={isVoidModalOpened}
        isSubmitButtonYesDisabled={voidInProgress}
        onModalClose={() => setIsSidebarOpened(true)}
        onSubmitButtonNoClick={handleCancelVoidPayment}
        onSubmitButtonYesClick={handleVoid}
        setIsOpened={setIsVoidModalOpened}
        title={t('voidPayment')}
      />
      <VoidErrorMessageDialog
        isVoidModalOpened={isVoidErrorModalOpened}
        onModalClose={() => setIsVoidErrorModalOpened(false)}
        setIsVoidModalOpened={setIsVoidErrorModalOpened}
        voidErrorCode={voidErrorCode}
      />
    </>
  )
}
PaymentsTab.propTypes = {
  scope: PT.string,
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    overdueLevelGroups: PT.arrayOf(
      PT.shape({
        amountCents: PT.number,
        id: PT.string,
        overdueLevel: PT.shape({
          id: PT.string,
          title: PT.string,
          overdueFrom: PT.string,
          overdueTo: PT.string,
        }),
      }),
    ),
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
  isAllContracts: PT.bool,
}

PaymentsTab.defaultProps = {
  scope: '',
  contractData: {},
  isAllContracts: false,
}

export default PaymentsTab
